<template>
  <div class="pbf">
     
     <BrandTabReviews
      :inurl="'/reviews'"   
      :grower-id="props.id"   
      />

  </div>
</template>

<script setup lang="ts">

interface Props {
  user: User;
  id: number;
}

const props = defineProps<Props>();

useHead(useNuxtApp().$head.getGrowerReviews(props.user));

</script>

<style scoped>
 

</style>
